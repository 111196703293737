/* الأسلوب الأساسي لجميع القوالب */
.article-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Times New Roman', serif;
}

/* عنوان العمود */
.column-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  font-family: 'Old English Text MT', 'Times New Roman', serif;
  color: #000;
}

/* رأس المقال */
.article-header {
  margin-bottom: 0rem;
}

.article-title {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.article-meta {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: -3rem;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.author-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.author-name {
  font-weight: bold;
  font-size: 1.1rem;
}

.article-date {
  color: #666;
  font-style: italic;
}

.article-featured-image {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
}

.article-featured-image img {
  max-width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
}

/* محتوى المقال */
.article-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
}

/* القالب القياسي */
.template-standard-article .article-content {
  max-width: 800px;
  margin: 0 auto;
}

/* قالب عمودين */
.template-two-column .article-content {
  column-count: 2;
  column-gap: 3rem;
}

/* قالب الموضة */
.template-fashion {
  background-color: #f9f7f2;
}

.template-fashion .article-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.template-fashion .article-featured-image {
  grid-column: 1 / -1;
}

/* قالب الطاقة المتجددة */
.template-renewable-energy .article-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 3rem;
}

.template-renewable-energy blockquote {
  font-size: 1.4rem;
  font-style: italic;
  border-left: 4px solid #000;
  padding-left: 1.5rem;
  margin: 2rem 0;
}

/* قالب الاستوديو */
.template-studio {
  background-color: #f9f7f2;
}

.template-studio .article-featured-image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-bottom: 3rem;
}

.template-studio .article-featured-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* قالب الأزياء الرجعية */
.template-retro {
  background-color: #f9f7f2;
}

.template-retro .article-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.template-retro .article-featured-image {
  margin: 2rem 0;
}

/* أزرار التفاعل */
.article-actions {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 3rem 0;
  padding: 1rem 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.action-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.action-button:hover {
  color: #000;
}

.bookmark-button.bookmarked {
  color: #000;
  font-weight: bold;
}

/* قسم التعليقات */
.comments-section {
  max-width: 800px;
  margin: 3rem auto 0;
  padding-top: 2rem;
  border-top: 2px solid #eee;
}

.comments-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.comment-form {
  margin-bottom: 2rem;
}

.comment-form-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.comment-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-family: inherit;
}

.comment-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.comment {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.comment-content {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.comment-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.like-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.like-button.liked {
  color: #000;
  font-weight: bold;
}

.delete-button {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
}

/* التحميل والأخطاء */
.loading-spinner {
  text-align: center;
  padding: 3rem;
  font-size: 1.2rem;
}

.error-message {
  text-align: center;
  padding: 3rem;
  color: #ff4444;
  font-size: 1.2rem;
}

/* الاستجابة للشاشات الصغيرة */
@media (max-width: 768px) {
  .article-page {
    padding: 1rem;
  }

  .article-title {
    font-size: 2rem;
  }

  .template-two-column .article-content,
  .template-fashion .article-content,
  .template-renewable-energy .article-content,
  .template-retro .article-content {
    grid-template-columns: 1fr;
    column-count: 1;
  }

  .article-actions {
    flex-wrap: wrap;
  }
} 
