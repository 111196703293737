:root {
  --logo-size: 220px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 185px;
  background-color: #f9f7f2;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.header-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 5px 0;
  color: #444;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 5px 0 15px;
}

.logo-area {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.logo-image {
  max-width: var(--logo-size);
  height: auto;
}

.header-ad-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 100px;
}

.header-ad {
  width: 220px;
  height: 110px;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 1.2rem;
}

.line-image-top-right,
.line-image-top-left {
  position: absolute;
  top: -8px;
  width: 450px;
  height: 30px;
  z-index: 1;
}

.line-image-top-right {
  right: 70px;
}

.line-image-top-left {
  left: 70px;
}

.nav-container {
  width: 100%;
  margin-top: -25px;
  position: relative;
}

.line-image-above-nav,
.line-image-below-nav {
  width: 100%;
  height: 25px;
  background-repeat: repeat-x;
  background-size: auto 100%;
  position: relative;
  z-index: 1;
}

.line-image-above-nav {
  margin-bottom: -10px;
}

.line-image-below-nav {
  margin-top: -10px;
}

.nav {
  padding: 4px 0;
  margin: 5px 0;
  position: relative;
  z-index: 2;
}

.nav-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  align-items: center;
  column-gap: 15px;
}

.nav-item:nth-child(1) { /* HOME */
  grid-column: 1;
  justify-self: center;
}

.nav-item:nth-child(2) { /* Bookmark */
  grid-column: 2;
  justify-self: center;
}

.nav-item.write-center { /* Write */
  grid-column: 3;
  position: relative;
  left: auto;
  transform: none;
  justify-self: center;
}

.nav-item:nth-child(4) { /* ADs */
  grid-column: 4;
  justify-self: center;
}

.nav-item:nth-child(5) { /* LOGIN/MY COLUMN */
  grid-column: 5;
  justify-self: center;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  white-space: nowrap;
}

.nav-icon {
  margin: 0 8px;
}

.bookmark-icon {
  width: 50px;
  height: 50px;
  transform: translateY(0);
}

.write-icon {
  width: 60px;
  height: 60px;
  transform: translateY(0);
}

.auth-button {
  background: none;
  border: none;
  color: #333;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease;
}

.auth-button:hover {
  color: #000;
  text-decoration: underline;
}

.nav-item .welcome-text {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  color: #343434;
  padding: 4px 8px;
  border-radius: 3px;
  transition: all 0.2s ease;
}

.nav-item .welcome-text:hover {
  color: #000;
  border-color: #000;
}

