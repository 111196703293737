.footer {
  border-top: 2px solid #000;
  padding: 20px 0;
  text-align: center;
  margin-top: 30px;
  font-family: 'EB Garamond', serif;
  font-size: 1rem;
  color: #444;
  background-color: #f9f7f2;
  letter-spacing: 0.3px;
}
