/* --- ملف customQuillStyles.css --- */
/* تنسيقات لجعل محرر Quill يبدو كلاسيكيًا ومناسبًا للغة الإنجليزية (LTR) */

/* حاوية المحرر الرئيسية */
.editor-container.classic-theme {
  border: 1px solid #ccc; /* حد رمادي حول المحرر */
  background-color: #f9f9f9; /* خلفية رمادية فاتحة جدًا */
  border-radius: 4px; /* حواف دائرية خفيفة */
  font-family: 'Georgia', 'Times New Roman', Times, serif; /* خط Serif كلاسيكي */
  margin-bottom: 2rem; /* هامش سفلي */
}

/* رأس المحرر (العنوان واختيار القالب) */
.editor-header {
  padding: 1.5rem;
  border-bottom: 2px solid #eee; /* خط فاصل أثقل قليلاً */
  background-color: #fff; /* خلفية بيضاء للهيدر */
  border-radius: 4px 4px 0 0; /* حواف دائرية علوية */
}

.form-group {
  margin-bottom: 1.5rem; /* مسافة أسفل كل مجموعة */
}
.form-group:last-child {
  margin-bottom: 0; /* إزالة المسافة السفلية لآخر عنصر */
}


.settings-label {
  display: block;
  margin-bottom: 0.6rem;
  font-weight: bold;
  color: #444; /* لون رمادي أغمق */
  font-size: 0.9rem;
  text-transform: uppercase; /* أحرف كبيرة */
  letter-spacing: 0.5px; /* تباعد بسيط بين الأحرف */
}

.settings-input.title-input {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 1.3rem; /* حجم أكبر للعنوان */
  font-family: inherit; /* يرث الخط الكلاسيكي */
  box-sizing: border-box; /* لضمان أن الحشو لا يزيد العرض */
}
.settings-input.title-input:focus {
  border-color: #aaa;
  outline: none; /* إزالة المخطط الافتراضي */
  box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.1); /* إضافة ظل خفيف عند التركيز */
}

/* --- أزرار اختيار القالب --- */
.editor-templates-selection {
  margin-top: 1.5rem;
}

.template-buttons-container {
  display: flex;
  flex-wrap: wrap; /* السماح بالالتفاف في الشاشات الصغيرة */
  gap: 0.75rem; /* مسافة بين الأزرار */
}

.template-button {
  padding: 0.7rem 1.3rem; /* زيادة الحشو */
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-family: inherit;
  font-size: 0.9rem;
  text-transform: capitalize; /* جعل أول حرف كبير */
}

.template-button:hover {
  background-color: #f0f0f0; /* لون أفتح قليلاً عند المرور */
  border-color: #bbb;
}

.template-button.active {
  background-color: #4a4a4a; /* لون رمادي داكن للقالب النشط */
  color: white;
  border-color: #4a4a4a;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* ظل خفيف للزر النشط */
}

/* --- تنسيقات Quill --- */

/* الحاوية التي ستطبق عليها الأعمدة (للمعاينة داخل المحرر) */
.editor-quill-wrapper {
  padding: 0 1.5rem; /* هوامش داخلية أفقية فقط */
  background-color: #fff; /* خلفية بيضاء لمنطقة الكتابة */
}

/* تطبيق الأعمدة للمعاينة بناءً على الكلاس المضاف */
/* ملاحظة: الأعمدة تعمل بشكل أفضل مع نص كافٍ لملئها */
.editor-quill-wrapper.columns-1 .ql-editor { column-count: 1; }
.editor-quill-wrapper.columns-2 .ql-editor { column-count: 2; column-gap: 2.5rem; }
.editor-quill-wrapper.columns-3 .ql-editor { column-count: 3; column-gap: 2rem; }

/* تعديل شريط أدوات Quill ليبدو أفضل وأكثر تنظيمًا */
.ql-toolbar.ql-snow {
  border: none; /* إزالة حدود شريط الأدوات الافتراضية */
  border-top: 1px solid #eee; /* خط فاصل علوي */
  border-bottom: 1px solid #eee; /* خط فاصل سفلي */
  padding: 10px 8px; /* حشو مناسب */
  background-color: #fdfdfd; /* لون خلفية فاتح جدًا للشريط */
  position: sticky; /* تثبيت شريط الأدوات عند التمرير */
  top: 0; /* يلتصق بأعلى الحاوية */
  z-index: 10;
  display: flex; /* استخدام flex لتوزيع أفضل */
  flex-wrap: wrap; /* السماح بالتفاف الأزرار */
  gap: 5px 10px; /* مسافات بين مجموعات الأزرار والأزرار نفسها */
  border-radius: 0; /* إزالة أي تدوير حواف قد يرثها */
}

/* تنسيق مجموعات الأزرار */
.ql-formats {
  margin-right: 8px !important; /* مسافة بين المجموعات */
  display: inline-flex; /* جعل الأزرار داخل المجموعة متجاورة */
  gap: 3px; /* مسافة صغيرة بين الأزرار داخل نفس المجموعة */
}
.ql-formats:last-child {
  margin-right: 0 !important; /* إزالة المسافة من آخر مجموعة */
}

/* تنسيق الأزرار نفسها */
.ql-toolbar.ql-snow button {
  width: auto; /* عرض تلقائي */
  padding: 4px 6px; /* حشو أصغر قليلاً */
  border: 1px solid transparent; /* حدود شفافة مبدئيًا */
  border-radius: 3px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;
  display: inline-flex; /* لمحاذاة الأيقونات والنصوص إن وجدت */
  align-items: center;
  justify-content: center;
  height: 28px; /* ارتفاع ثابت للأزرار */
  min-width: 28px; /* عرض أدنى */
}
.ql-toolbar.ql-snow button:hover {
   background-color: #eee; /* تغيير لون الخلفية عند المرور */
   border-color: #ccc; /* إظهار حد خفيف */
}
.ql-toolbar.ql-snow button.ql-active {
   background-color: #e0e0e0; /* لون خلفية أغمق للزر النشط */
   border-color: #bbb;
   color: #333; /* لون النص للزر النشط */
}
/* تعديلات لأزرار القوائم المنسدلة إن وجدت (لا نستخدمها هنا بشكل أساسي) */
.ql-toolbar .ql-picker {
   height: 28px;
   border-radius: 3px;
   border: 1px solid transparent;
}
.ql-toolbar .ql-picker:hover {
   background-color: #eee;
   border-color: #ccc;
}
.ql-toolbar .ql-picker.ql-active {
   background-color: #e0e0e0;
   border-color: #bbb;
}


/* تنسيق منطقة الكتابة */
.ql-container.ql-snow {
  border: none; /* إزالة حدود منطقة الكتابة */
  font-size: 1rem; /* حجم خط أساسي للمحتوى */
}

.ql-editor {
  min-height: 400px; /* الحفاظ على الارتفاع الأدنى */
  line-height: 1.7; /* زيادة تباعد الأسطر قليلاً للقراءة */
  padding: 1.5rem 0.5rem; /* حشو علوي/سفلي أكبر، أفقي أقل */
  font-family: inherit; /* يرث الخط الكلاسيكي */
  direction: ltr; /* التأكيد على الاتجاه لليسار */
  text-align: left; /* التأكيد على المحاذاة لليسار */
  background-color: #f9f7f2; /* تغيير الخلفية إلى اللون الموحد */
  outline: none; /* إزالة المخطط عند التركيز على المحرر */
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05); /* ظل داخلي خفيف */
}

/* تنسيق placeholder لـ LTR */
.ql-editor.ql-blank::before {
  color: #aaa; /* لون أفتح للنص المؤقت */
  font-style: italic;
  left: 0.5rem; /* ضبط الموقع ليتناسب مع حشو .ql-editor */
  right: auto; /* إلغاء تحديد الموقع من اليمين */
  position: absolute;
  pointer-events: none; /* لمنع التفاعل مع النص المؤقت */
}


/* تذييل المحرر (زر النشر) */
.editor-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #eee; /* خط فاصل علوي */
  text-align: right; /* محاذاة زر النشر لليمين */
  background-color: #f9f9f9; /* نفس لون خلفية الحاوية */
  border-radius: 0 0 4px 4px; /* حواف دائرية سفلية */
}

/* زر النشر */
.publish-button {
  padding: 0.8rem 2rem; /* حشو كبير لجعله بارزًا */
  font-size: 1rem;
  font-weight: bold;
  background-color: #5cb85c; /* لون أخضر للزر الرئيسي */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.publish-button:hover {
  background-color: #4cae4c; /* لون أغمق عند المرور */
}

.publish-button:disabled {
    background-color: #ccc; /* لون رمادي للزر المعطل */
    cursor: not-allowed;
}


/* --- تعديلات للشاشات الصغيرة (Responsive) --- */
@media (max-width: 768px) {
  /* جعل الأعمدة عمودًا واحدًا في المعاينة على الشاشات الصغيرة */
  .editor-quill-wrapper.columns-2 .ql-editor,
  .editor-quill-wrapper.columns-3 .ql-editor {
    column-count: 1;
  }

   .template-buttons-container {
     gap: 0.5rem; /* تقليل المسافة بين أزرار القوالب */
   }
   .template-button {
      padding: 0.6rem 1rem; /* تصغير أزرار القوالب قليلاً */
   }

   .settings-input.title-input {
      font-size: 1.2rem; /* تصغير خط العنوان */
   }

    .ql-toolbar.ql-snow {
      padding: 8px 5px; /* تقليل الحشو أكثر */
      gap: 3px 5px; /* تقليل المسافات أكثر */
    }
    .ql-toolbar.ql-snow button {
      height: 26px; /* تصغير ارتفاع الأزرار */
      min-width: 26px;
    }

}

@media (max-width: 576px) {
   .editor-header, .editor-footer {
     padding: 1rem; /* تقليل الحشو في الهيدر والفوتر */
   }
   .editor-quill-wrapper {
      padding: 0 1rem; /* تقليل الحشو الأفقي للمحرر */
   }
   .ql-editor {
     padding: 1rem 0.25rem; /* تقليل حشو المحرر */
     min-height: 300px; /* تقليل الارتفاع الأدنى */
   }
   .publish-button {
     padding: 0.7rem 1.5rem; /* تصغير زر النشر */
     width: 100%; /* جعل الزر يأخذ كامل العرض */
     box-sizing: border-box;
   }
   .editor-footer {
      text-align: center; /* توسيط الزر في الفوتر */
   }
}