body {
  font-family: 'EB Garamond', serif;
  direction: ltr;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #f9f7f2;
  border-left: 2px solid #222;
  border-right: 2px solid #222;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header-logo {
  font-size: 2rem;
  font-weight: bold;
}

.header-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  color: #555;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  padding: 0.25rem 0;
}

ul.bookmark-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.bookmark-list li {
  border-bottom: 1px dotted #aaa;
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.bookmark-list li a {
  text-decoration: none;
  font-weight: bold;
  color: #000;
}

ul.bookmark-list li a:hover {
  text-decoration: underline;
}

ul.bookmark-list li button {
  font-size: 0.85rem;
  background: none;
  border: none;
  color: #900;
  cursor: pointer;
}

ul.bookmark-list li button:hover {
  text-decoration: underline;
}

.footer {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 3rem;
  border-top: 1px solid #aaa;
  padding-top: 1rem;
  color: #444;
}
