/* Responsive Design Styles */
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
  
  .header-ad {
    width: 160px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
  
  .header-logo {
    font-size: 2.5rem;
  }
  
  .header-ad {
    width: 120px;
    height: 80px;
  }
  
  .article-grid {
    grid-gap: 0.75rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
  
  .header-top {
    flex-direction: column;
  }
  
  .header-logo {
    margin: 1rem 0;
    order: -1;
  }
  
  .header-ad {
    width: 100%;
    max-width: 300px;
    margin: 0.5rem 0;
  }
  
  .nav-list {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .nav-item {
    margin: 0.25rem 0.75rem;
  }
  
  .article-card[style*="grid-column: span 6"] {
    grid-column: span 12 !important;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 0.5rem;
  }
  
  .header-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .article-title {
    font-size: 1.5rem;
  }
  
  .editor-toolbar {
    flex-direction: column;
  }
  
  .editor-toolbar > div {
    margin-bottom: 0.5rem;
  }
  
  .editor-button {
    padding: 0.25rem 0.5rem;
    margin: 0.1rem;
  }
  
  .auth-modal-content,
  .settings-modal-content {
    width: 90%;
    max-width: 400px;
  }
}
