/* --- homepageLayout.css --- */
/* تنسيقات للصفحة الرئيسية بناءً على النمط المطلوب */

/* حاوية الصفحة الرئيسية */
.homepage-container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px;
  transform: scale(0.54);
  transform-origin: center top;
  width: 100%;
  font-family: 'EB Garamond', serif;
}

/* رسائل الصفحة (التحميل، الخطأ، لا يوجد مقالات) */
.loading-indicator,
.page-message {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

/* حاوية النمط المتكرر */
.article-pattern {
  margin-bottom: 50px;
  width: 100%;
}

/* الخط الفاصل الأفقي الرئيسي */
.pattern-divider.horizontal {
  border: none;
  border-top: 1px solid #000;
  margin: 30px 0;
  width: 100%;
}

/* الخط الفاصل بين الأنماط */
.pattern-divider.pattern-separator {
  border: none;
  border-top: 2px solid #000;
  margin: 50px 0;
  width: 100%;
}

/* الخط الفاصل العمودي */
.pattern-divider.vertical {
  border: none;
  border-left: 1px solid #000;
  height: auto;
  margin: 0;
  align-self: stretch;
}

/* صفوف النمط */
.pattern-row {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}

/* المقال الرئيسي (النمط الكامل) */
.article-snippet {
  display: grid;
  grid-template-areas:
    "meta title title"
    "excerpt excerpt image";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr; /* This ensures the second row expands */
  gap: 1.5rem;
}

.article-snippet.no-image {
  grid-template-areas:
    "meta title title"
    "meta content content";
}

/* معلومات العمود والكاتب */
.article-meta {
  grid-area: meta;
  grid-row: 1 / 2; /* لا يتمدد فوق الصف الثاني */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.article-column-title {
  background: #000;
  color: #fff;
  padding: 0.75rem;
  font-family: 'Times New Roman', serif;
  font-weight: bold;
  text-align: center;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.article-author-info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.author-name {
  color: #000;
  font-weight: bold;
  font-size: 1.1em;
  margin: 0;
  font-family: 'Times New Roman', serif;
}

.article-date {
  color: #666;
  font-size: 0.9em;
  margin: 0;
  font-style: italic;
}

.meta-excerpt {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.6;
  color: #000;
}

/* عنوان المقال */
.article-title {
  grid-area: title;
  grid-row: 1 / 2;
}

.article-title-main {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  font-family: 'Times New Roman', serif;
  letter-spacing: -0.5px;
}

.article-title-sub {
  font-size: 1.5rem;
  color: #666;
  line-height: 1.3;
  margin-top: 0.5rem;
  font-family: 'Times New Roman', serif;
  font-style: italic;
}

.article-title a {
  color: inherit;
  text-decoration: none;
}

/* محتوى المقال */
.article-content {
  padding-block-start: 100px;
  grid-area: content;
  grid-row: 2 / 3; /* يبدأ من الصف الثاني إلى الصف الرابع (غير شامل) */
  font-size: 1.1rem;
  line-height: 1.7;
}

.article-content::first-letter {
  font-size: 2em;
  font-weight: bold;
}

.article-excerpt {
  grid-area: excerpt;
  column-count: 2;
  column-gap: 2rem;
  text-align: justify;
  font-family: 'Times New Roman', serif;
  height: 100%; /* Make the excerpt take full height */
  min-height: 569px; /* Set a minimum height to ensure content fills space */
}

/* For better column balancing */
.article-excerpt::after {
  content: "";
  display: block;
  height: 0;
}

.article-snippet.layout-full.no-image .article-excerpt {
  --column-count: 2;
}

/* صورة المقال */
.article-image-container {
  grid-area: image;
  grid-row: 2 / 4;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: -webkit-fill-available;;
  object-fit: cover;
}

/* صف الإعلانات */
.pattern-row-2 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 30px 0;
}

.ad-block {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: #666;
  font-family: 'Times New Roman', serif;
  font-style: italic;
}

.ad-block-medium {
  width: calc(50% - 15px);
  height: 250px;
}

.ad-block-narrow {
  width: 120px;
  height: 600px;
}

/* صف المقالين جنباً إلى جنب */
.pattern-row-3 {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 30px;
  margin: 30px 0;
}

/* الصف المعقد */
.pattern-row-4-5 {
  display: grid;
  grid-template-columns: 1fr 1px 120px 1px 1fr;
  gap: 10px;
  margin: 30px 0;
}

/* تنسيقات المقالات الأخرى */
.article-snippet.layout-left,
.article-snippet.layout-right,
.article-snippet.layout-text-only,
.article-snippet.layout-tall {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

/* قسم الصورة المميزة */
.image-feature-container {
  width: 100%;
  margin-top: 30px;
}

.image-feature-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.image-feature-caption {
  margin-top: 15px;
  text-align: center;
  font-family: 'Times New Roman', serif;
}

/* مؤشرات التحميل */
.loading-indicator,
.loading-more,
.error-message,
.no-articles-message {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

/* تحسينات للأجهزة المحمولة */
@media (max-width: 992px) {
  .homepage-container {
    padding: 15px;
  }

  .article-snippet {
    grid-template-areas:
      "meta"
      "title"
      "content"
      "image";
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .article-meta {
    width: 100%;
  }

  .article-title-main {
    font-size: 2rem;
  }

  .article-title-sub {
    font-size: 1.2rem;
  }

  .pattern-row-2,
  .pattern-row-3,
  .pattern-row-4-5 {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .pattern-divider.vertical {
    display: none;
  }

  .ad-block-medium {
    width: 100%;
    height: 200px;
  }

  .ad-block-narrow {
    width: 100%;
    height: 200px;
  }

  .article-excerpt {
    column-count: 1;
  }
}

@media (max-width: 576px) {
  .homepage-container {
    padding: 10px;
  }

  .article-title-main {
    font-size: 1.5rem;
  }

  .article-title-sub {
    font-size: 1rem;
  }

  .article-excerpt {
    font-size: 1rem;
    line-height: 1.5;
  }

  .ad-block {
    padding: 20px;
    height: 150px;
  }
}

/* تعديل تنسيق العمودين */
.layout-full .meta-excerpt, .layout-full .article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.layout-full .ad-block {
  margin-top: 10px;
}

.layout-full .article-snippet {
  margin-top: 20px;
}

.layout-full .bottom-line {
  display: none;
}

/* حذف الخط السفلي تحت الإعلانات */
.layout-full .ad-line {
  display: none;
}

/* حذف الخط السفلي تحت الإعلانات */
.pattern-row-2 + .pattern-divider.horizontal {
  display: none;
}

/* تنسيق المقالات */
.layout-row {
  display: flex;
  justify-content: space-between;
}

.article-snippet-1 {
  width: 40%;
}

.article-snippet-2 {
  width: 60%;
}

/* الخط الفاصل بين المقالات */
.article-divider {
  width: 100%;
  height: 2px;
  background-color: #000;
  margin: 0 auto;
}

/* تنسيق عنوان العمود والتاريخ */
.column-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.article-author {
  font-size: 0.9em;
  margin-bottom: 2px;
}

.article-date {
  font-size: 0.8em;
  color: #666;
}

/* تنسيق المقال في الصف الثالث */
.article-snippet.layout-side {
  display: grid;
  grid-template-areas:
    "meta title"
    "meta content";
  grid-template-columns: auto 1fr;
  gap: 20px;
}

.article-snippet.layout-side.has-image {
  display: grid;
  grid-template-areas:
    "meta title"
    "meta content"
    "image content";
  grid-template-columns: auto 1fr;
  gap: 20px;
}

.article-snippet.layout-side .article-meta {
  grid-area: meta;
  width: 150px;
}

.article-snippet.layout-side .article-title {
  grid-area: title;
  font-size: 2em;
  line-height: 1.2;
  margin-bottom: 15px;
}

.article-snippet.layout-side .article-content {
  grid-area: content;
  font-size: 1em;
  line-height: 1.6;
}

.article-snippet.layout-side .article-image-container {
  grid-area: image;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.article-snippet.layout-side .article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-snippet.layout-side .article-column-title {
  margin-bottom: 15px;
}

.article-snippet.layout-side .article-author-info {
  margin-bottom: 15px;
}

.article-snippet.layout-side .article-excerpt {
  column-count: 1;
}

/* تحسينات للأجهزة المحمولة */
@media (max-width: 768px) {
  .pattern-row-3 {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .pattern-divider.vertical {
    display: none;
  }

  .article-snippet.layout-side,
  .article-snippet.layout-side.has-image {
    grid-template-areas:
      "meta"
      "title"
      "content"
      "image";
    grid-template-columns: 1fr;
  }

  .article-snippet.layout-side .article-meta {
    width: 100%;
  }

  .article-snippet.layout-side .article-image-container {
    width: 100%;
    height: 200px;
  }
}
/* هذا الحل يستبدل هيكل grid بالكامل بهيكل مرن يعتمد على flexbox */

/* قم بإضافة هذا الكود في نهاية ملف CSS الخاص بك لضمان أنه يتجاوز الأنماط السابقة */

/* إعادة تعريف الهيكل الأساسي للمقال */
.article-snippet.layout-full {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* الصف العلوي للعنوان والمعلومات */
.article-top-row {
  display: flex;
  gap: 1.5rem;
}

.article-meta {
  width: 100%;
}

.article-title {
  width: 100%;
}

/* الصف السفلي للمحتوى والصورة */
.article-content-row {
  display: flex;
  gap: 1.5rem;
  min-height: 350px; /* ارتفاع ثابت مناسب */
}

.article-excerpt-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.article-image-container {
  width: 30%;
}

/* تعديل مظهر المقتطف */
.article-excerpt {
  font-size: x-large;
  column-fill: balance;
  column-count: 2;
  column-gap: 2rem;
  height: 100%;
}

/* تأكد من التوافق مع الهواتف المحمولة */
@media (max-width: 992px) {
  .article-top-row,
  .article-content-row {
    flex-direction: column;
  }
  
  .article-meta,
  .article-title,
  .article-excerpt-wrapper,
  .article-image-container {
    width: 100%;
  }
}

/* -------- style-left -------- */

.article-snippet.layout-side.style-left.has-image {
  display: grid;
  height: 100%;
  grid-template-areas:
    "image title"
    "image meta"
    "image excerpt";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-left.no-image {
  display: grid;
  grid-template-areas:
    "title"
    "meta"
    "excerpt";
  grid-template-columns: 1fr;
  gap: 15px;
}

.article-snippet.layout-side.style-left .article-image-container {
  grid-area: image;
  width: 150px;
  height: 100%;
  border: none;
}

.article-snippet.layout-side.style-left .article-excerpt {
  grid-area: excerpt;
  column-count: 2;
  max-height: 6.6em;
  overflow: hidden;
}


/* -------- style-right -------- */

.article-snippet.layout-side.style-right.has-image {
  display: grid;
  grid-template-areas:
    "meta title"
    "meta excerpt"
    "meta image";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-right.no-image {
  display: grid;
  grid-template-areas:
    "meta title"
    "meta excerpt";
  grid-template-columns: auto 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-right .article-image-container {
  grid-area: image;
  width: 100%;
  height: 150px;
}

.article-snippet.layout-side.style-right .article-excerpt {
  grid-area: excerpt;
  column-count: 2;
  overflow: hidden;
}

/* ========== مقتطف أيمن style-right ========== */

/* مع صورة */
.article-snippet.layout-side.style-right.has-image {
  display: grid;
  grid-template-areas:
    "meta image"
    "title title"
    "excerpt excerpt";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-right.no-image {
  display: grid;
  grid-template-areas:
    "meta title"
    "excerpt excerpt";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-right .article-meta {
  grid-area: meta;
  width: 100%;
}

.article-snippet.layout-side.style-right .article-title {
  grid-area: title;
  width: 100%;
}

.article-snippet.layout-side.style-right .article-image-container {
  grid-area: image;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.article-snippet.layout-side.style-right .article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-snippet.layout-side.style-right .article-excerpt {
  grid-area: excerpt;
  column-count: 2;
  column-gap: 2rem;
  overflow: hidden;
}


/* ========== مقتطف أيسر style-left ========== */

/* مع صورة */
.article-snippet.layout-side.style-left.has-image {
  display: grid;
  grid-template-areas:
    "image image"
    "meta meta"
    "title title"
    "excerpt excerpt";
  grid-template-columns: 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-left.no-image {
  display: grid;
  grid-template-areas:
    "excerpt meta"
    "excerpt title"
    "excerpt excerpt";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.article-snippet.layout-side.style-left .article-meta {
  grid-area: meta;
  margin-bottom: -90px;
  width: 100%;
}

.article-snippet.layout-side.style-left .article-title {
  grid-area: title;
  width: 100%;
}

.article-snippet.layout-side.style-left .article-image-container {
  grid-area: image;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.article-snippet.layout-side.style-left .article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-snippet.layout-side.style-left .article-excerpt {
  grid-area: excerpt;
  column-count: 2;
  column-gap: 2rem;
  overflow: hidden;
}

/* === مقتطف بعمودين متوازنين تلقائيًا باستخدام column-count === */
.article-excerpt-flex {
  grid-area: excerpt;
  column-count: 2;
  column-gap: 2rem;
  text-align: justify;
  font-family: 'Times New Roman', serif;
  font-size: 1rem;
  line-height: 1.6;
  overflow: hidden;
  max-height: 6.6em;
}