.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-modal-content {
  background-color: #f9f7f2;
  padding: 2rem;
  border: 2px solid #333;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  font-family: 'EB Garamond', serif;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
}

.auth-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #aaa;
}

.auth-tab {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
  border-bottom: 3px solid transparent;
}

.auth-tab.active {
  border-bottom: 3px solid #343434;
  color: #000;
}

.auth-form-group {
  margin-bottom: 1rem;
}

.auth-label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.auth-input {
  width: 100%;
  padding: 0.5rem;
  font-family: 'EB Garamond', serif;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #343434;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.auth-button:hover {
  background-color: #000;
}
