/* Reset CSS for removing default borders */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'EB Garamond', serif;
  color: #333;
  background-color: #f9f7f2;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Header Styles */
.header {
  padding: 20px 0;
  transform: scale(0.5);
  transform-origin: top center;
  margin: -100px auto; /* تعويض المساحة الزائدة بعد التصغير */
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-logo {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
  font-family: 'EB Garamond', serif;
}

.header-ad {
  width: 200px;
  height: 100px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
}

.header-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/* Navigation */
.nav {
  padding: 10px 0;
}

.nav-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-item a:hover {
  color: #888;
}

/* Article Grid Layout */
.article-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  margin: 20px 0;
}

/* Article Styles - No Boxes */
.article {
  margin-bottom: 20px;
}

.article-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.2;
}

.article-content {
  margin-bottom: 15px;
}

.article-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.article-link {
  color: blue;
  font-style: italic;
  text-decoration: none;
}

.article-link:hover {
  text-decoration: underline;
}

.article-author {
  font-style: italic;
  margin-bottom: 10px;
}

/* Ad Styles */
.ad-block {
  border: 1px solid #000;
  padding: 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

/* Article Patterns */
.article-pattern-1 {
  grid-column: span 12;
}

.article-pattern-2-left {
  grid-column: span 6;
}

.article-pattern-2-right {
  grid-column: span 6;
}

.article-pattern-3-main {
  grid-column: span 8;
}

.article-pattern-3-side {
  grid-column: span 4;
}

.article-pattern-4-quarter {
  grid-column: span 3;
}

.article-pattern-5-third {
  grid-column: span 4;
}

/* Footer */
.footer {
  border-top: 2px solid #000;
  padding: 20px 0;
  text-align: center;
  margin-top: 30px;
}

/* Infinite Scroll Indicator */
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 992px) {
  .article-pattern-4-quarter {
    grid-column: span 6;
  }
  
  .article-pattern-5-third {
    grid-column: span 6;
  }
}

@media (max-width: 768px) {
  .header-top {
    flex-direction: column;
  }
  
  .header-ad {
    margin: 10px 0;
  }
  
  .article-pattern-2-left,
  .article-pattern-2-right,
  .article-pattern-3-main,
  .article-pattern-3-side {
    grid-column: span 12;
  }
}

@media (max-width: 576px) {
  .article-pattern-4-quarter,
  .article-pattern-5-third {
    grid-column: span 12;
  }
  
  .nav-list {
    flex-direction: column;
    align-items: center;
  }
  
  .nav-item {
    margin-bottom: 10px;
  }
}
